import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import { Col, Container, Row } from 'react-bootstrap';
import Head from 'next/head';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const counterImage = '/assets/img/calling-friend.webp';
const HomeBlogPage = dynamic(() => import('./Components/HomeBlogPage'));
const TopPage = dynamic(() => import('./Components/TopPage'));

const cityData = [
  {
    cityName: 'Call Girls Bangalore',
    link: '/escorts/bangalore',
    imageLink: 'assets/img/banglore.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Jaipur',
    link: '/escorts/jaipur',
    imageLink: 'assets/img/jaipur.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Pune',
    link: '/escorts/pune',
    imageLink: 'assets/img/pune.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Mumbai',
    link: '/escorts/mumbai',
    imageLink: 'assets/img/mumbai.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Chennai',
    link: '/escorts/Chennai',
    imageLink: 'assets/img/chennai.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Hyderabad',
    link: '/escorts/Hyderabad',
    imageLink: 'assets/img/hyderabad.webp',
    description: 'Hire Call Girls & Escorts',
  },
  {
    cityName: 'Call Girls Udaipur',
    link: '/escorts/udaipur',
    imageLink: 'assets/img/udaipur.webp',
    description: 'Hire Call Girls & Escorts',
  },
];

const Index = (props: any) => {
  const { listData } = props;
  const [activeIndex, setActiveIndex] = useState<string>('');

  const handleToggle = (index: string) => {
    setActiveIndex(prevIndex => (prevIndex === index ? '' : index));
  };
  return (
    <>
      <Head>
        <link rel="preload" href="/assets/img/website-banner.webp" as="image" />
        <link rel="preload" href="/assets/img/website-banner-mob.webp" as="image" />
        <link rel="preload" href="/assets/img/free-badge.webp" as="image" />
        <link rel="preload" href="/assets/img/free-badge-mob.webp" as="image" />
      </Head>
      <div className="counter-wrap">
        <div className="counter-bg">
          <Image
            quality={75}
            src={counterImage}
            width={1000}
            height={1000}
            alt="Discover the Best Call Girls in India Today"
          />
        </div>
        <Container>
          <Row>
            <Col sm={4} xs md={4}>
              <div className="counter-box">
                <p>
                  1000+ <span>Happy Client</span>
                </p>
              </div>
            </Col>
            <Col sm={4} xs md={4}>
              <div className="counter-box">
                <p>
                  100+ <span> City Listing</span>
                </p>
              </div>
            </Col>
            <Col sm={4} xs md={4}>
              <div className="counter-box">
                <p>
                  700+ <span> Done Project</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <TopPage adsData={listData.adsData} />
      <div className="why-choose">
        <Container>
          <h2>Why Choose 247torax?</h2>
          <p>
            Escort call girls from 247torax are well known for the wide range of services they offer. We come up with
            specialized and customized services per the client's needs and preferences. Your companions are well-trained
            in social etiquette and can create a comfortable and engaging atmosphere for the clients. Moreover, we focus
            on customer satisfaction and can deliver a unique and enjoyable experience that you will remember for the
            rest of your life.
          </p>
        </Container>
      </div>
      <div className="top-city">
        <div className="container">
          <h2>Top Cities for Indian Call Girls and Escort Services</h2>
          <div className="row">
            {cityData.map((item: any, index: number) => (
              <div key={index} className="col-lg-3 col-md-4 ">
                <div className="top-single-city">
                  <Link href={item.link}>
                    <div className="city-img">
                      <img loading="lazy" src={item.imageLink} alt={item.cityName} />
                      <div className="city-cont">
                        <div className="city-name">
                          <h3>{item.cityName} </h3>
                        </div>
                        <div className="hire-cg-wrap">
                          <p>{item.description} </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ads-free-sec">
        <Container>
          <div className="title-cls free-ads-para">
            <h2>Indian Escort Services from 247torax</h2>
            <p>
              You might be tired of the hectic life circle and in the mood to relax and wish to have fun. Why not book
              the best services from an <Link href={'/escorts'}> Indian escort service </Link>? 247torax offers the
              ultimate sexual experience to the clients irrespective of the city and the desire you have. Your friends
              are at your service at any point in time. We have been popular for our professionalism, discretion, and
              commitment to maintaining client confidentiality. One can get a cultural and fulfilling experience to get
              companionship and escort services.
            </p>
            <Link href="/ads-post/free" className="btn_primary" role="button">
              Click Here
            </Link>
          </div>
          <div className="post-banner">
            <Link href="/ads-post/free">
              <Image
                width={1800}
                height={450}
                src={'/assets/img/website-banner.webp'}
                alt="Free Ads"
                className="desktop-img"
              />

              <img loading="lazy" src="/assets/img/website-banner-mob.webp" alt="Free Ads" className="mob-img" />
            </Link>
            <div className="post-badge">
              <Link href="/ads-post/free">
                <Image
                  width={100}
                  height={100}
                  src={'/assets/img/free-badge.webp'}
                  alt="Free Ads"
                  className="desktop-img"
                />
                <Image
                  width={100}
                  height={100}
                  src={'/assets/img/free-badge-mob.webp'}
                  alt="Free Ads"
                  className="mob-img"
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <HomeBlogPage blogsData={listData.latestBlogs} />
      <section className="find-sec faq-sec">
        <div className="container">
          <div className="title-cls">
            <h2> Frequently Asked Questions About Call Girls & Escorts Services</h2>
          </div>
          <div className="accordion-main">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button ${activeIndex === 'One' ? '' : ' collapsed'}`}
                    type="button"
                    onClick={() => handleToggle('One')}
                  >
                    1. How are Indian escort services available?
                  </button>
                </h3>
                <div
                  id={`collapseOne`}
                  className={`accordion-collapse collapse ${activeIndex === 'One' ? ' show' : ''}`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      An Indian escort service is available with several options as per your choice and preferences.
                      This is in the form of companionship services like independent escorts and agency-based services.
                      If you are looking for the best agency-based services in India consider opting for 247 Torax.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${activeIndex === 'Two' ? '' : ' collapsed'}`}
                    type="button"
                    onClick={() => handleToggle('Two')}
                  >
                    2. How can I select the best escort call girls?
                  </button>
                </h3>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${activeIndex === 'Two' ? ' show' : ''}`}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      You can easily have the option to select the best escort call girls. Several options are available
                      as per the age group, physical appearance, and personality type. Your naughty and loving call girl
                      Indian offers in-call and out-call services. This helps you to have the flexibility to choose the
                      preferred location as per your choice.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button ${activeIndex === 'Three' ? '' : ' collapsed'}`}
                    type="button"
                    onClick={() => handleToggle('Three')}
                  >
                    3. What services do Indian call girls provide?
                  </button>
                </h3>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${activeIndex === 'Three' ? ' show' : ''}`}
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Indian escort girls offer friendship and sexual services in exchange for money. These services are
                      legal in certain parts of India.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingFour">
                  <button
                    className={`accordion-button ${activeIndex === 'Four' ? '' : ' collapsed'}`}
                    type="button"
                    onClick={() => handleToggle('Four')}
                  >
                    4. How do the offering terms vary for the call girls in India?
                  </button>
                </h3>
                <div
                  id="collapseFour"
                  className={`accordion-collapse collapse ${activeIndex === 'Four' ? ' show' : ''}`}
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      The services for Indian call girls depend on the terms and conditions of the offer. This is in the
                      form of friendship sexual desires and getting engaged in social events.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
